<template>
  <div class="response success fadein" ref="response" v-show="response">
    {{ response }}
  </div>
  <div class="response error fadein" ref="errors" v-show="errors.length">
    <template v-for="(error, idx) in errors" :key="idx">
      <template v-if="errors.length > 1">
        <li>{{ error }}</li>
      </template>
      <template v-else>
        {{ error }}
      </template>
    </template>
  </div>
  <div class="loader" v-if="loading"></div>
  <form class="form" ref="form" @submit.prevent="form_submit">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__user.png" alt="" />
        </div>
        <div class="field__title">ID</div>
      </div>
      <div class="field__box field__box_prl field__box_br" title="Use prefix?">
        <input
          type="checkbox"
          name="showPrefix"
          checked
          data-prefix-switcher
          v-model="prefixSwitcher"
        />
      </div>
      <div class="field__box field__box_br" v-show="prefixSwitcher">
        <div class="prefix field__prefix">
          <div class="prefix__content">
            <input
              type="text"
              name="prefix"
              size="1"
              placeholder="prefix"
              v-model.trim="form.prefix"
              :minlength="prefixMinLength"
              :maxlength="prefixMaxLength"
              :required="prefixSwitcher"
            />
            <div class="field__tool prefix_field_tool">
              <div class="field__tool-trigger" ref="generate_prefix" @click="generate_prefix">
                <img src="@/assets/images/icons/ico__refresh.png" alt="Random prefix" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        type="text"
        name="login"
        v-model.trim="form.username"
        class="input"
        autocomplete="off"
        minlength="4"
        :maxlength="prefixSwitcher ? 10 : 14"
        placeholder="username"
        required
      />
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__lock.png" alt="Password" />
        </div>
        <div class="field__title">Password</div>
      </div>
      <input
        :type="passwordReveal ? 'text' : 'password'"
        class="input"
        name="password"
        data-inp-type="inp-1"
        v-model="form.password"
        ref="pwRevealTarget"
        minlength="8"
        maxlength="16"
        autocomplete="off"
        required
      />
      <div class="field__tool">
        <div
          class="field__tool-trigger"
          data-inp-target="inp-1"
          ref="pwRevealTrigger"
          @click="passwordReveal = !passwordReveal"
        >
          <img :src="passwordRevealIcon" alt="Password reveal" />
        </div>
      </div>
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__mail.png" alt="E-mail" />
        </div>
        <div class="field__title">E-mail</div>
      </div>
      <input
        type="email"
        name="email"
        v-model.trim="form.email"
        class="input"
        autocomplete="on"
        required
      />
    </div>

    <div class="protection protection_mb">
      <div class="protection__captcha">
        <vue-recaptcha
          v-if="showRecaptcha"
          :sitekey="recaptcha_sitekey"
          size="normal"
          theme="dark"
          :tabindex="0"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>
      </div>
    </div>

    <div class="agreement agreement_mb">
      <div class="agreement__box">
        <div class="agreement__check agreement__check_left">
          <input type="checkbox" name="userAgreement" v-model="form.userAgreement" required />
        </div>
        <div class="agreement__content">
          <a href="/user_agreement" target="_blank" class="agreement__link"
            >I agree with the terms and conditions</a
          >
        </div>
      </div>
    </div>

    <div class="agreement agreement_mb">
      <div class="agreement__box">
        <div class="agreement__check agreement__check_left">
          <input type="checkbox" name="rules" v-model="form.rules" required />
        </div>
        <div class="agreement__content">
          <a href="/rules" target="_blank" class="agreement__link">I agree with the rules</a>
        </div>
      </div>
    </div>

    <div class="form__btn-box">
      <button
        class="button"
        type="submit"
        :class="{ disabled: btn_disabled }"
        :disabled="btn_disabled"
      >
        <span class="button__content">Signup</span>
      </button>
    </div>
  </form>
</template>

<script>
import { recaptcha_sitekey } from '@/assets/js/recaptcha';
import signupConstants from '@/assets/js/signup.constants';
import cfg_axios from '@/config/cfg_axios';
import axios from 'axios';

import vueRecaptcha from 'vue3-recaptcha2';

export default {
  name: 'Signup',
  components: {
    vueRecaptcha,
  },
  props: {
    msg: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      recaptcha_sitekey,
      showRecaptcha: true,
      captcha: false,
      prefixMinLength: signupConstants.PREFIX_MIN_LENGTH,
      prefixMaxLength: signupConstants.PREFIX_MAX_LENGTH,
      prefixMin: signupConstants.PREFIX_MIN,
      prefixMax: signupConstants.PREFIX_MAX,
      prefixCheckRefs: [],
      initiated: false,
      btn_disabled: false,
      errors: [],
      response: null,
      passwordReveal: false,
      prefixSwitcher: true,
      prefixContentClicked: false,
      form: {
        username: null,
        password: null,
        email: null,
        prefix: null,
        userAgreement: false,
        rules: false,
      },
      loading: false,
    };
  },
  computed: {
    passwordRevealIcon() {
      return require(!this.passwordReveal
        ? '@/assets/images/icons/ico__eye.png'
        : '@/assets/images/icons/ico__eye-off.png');
    },
  },
  methods: {
    recaptchaVerified() {
      this.captcha = true;
    },
    recaptchaExpired() {
      this.captcha = false;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.captcha = false;
    },
    generate_prefix() {
      this.form.prefix = this.random_prefix();
    },
    async form_submit() {
      this.loading = true;
      this.btn_disabled = true;
      this.errors = [];
      this.response = null;

      const return_err = () => {
        setTimeout(() => {
          this.btn_disabled = false;
          this.errors = [];
        }, 7500);
        this.loading = false;
      };

      if (!this.valid_prefix() && this.prefixSwitcher) {
        this.errors.push('Prefix is invalid! Allowed length exactly 4, alpha numeric characters');
      }

      if (!this.valid_username()) {
        this.errors.push('Username is invalid! Allowed length: 4-12, alpha numeric characters');
      }

      if (!this.captcha) {
        this.errors.push('You should verify captcha');
      }

      if (this.errors.length) {
        return return_err();
      }

      await axios
        .get('https://validate.xeon.lv/email/validate/' + encodeURIComponent(this.form.email), {
          timeout: 10000,
          headers: cfg_axios.headers,
        })
        .then(res => {
          console.log('verified', res.data.verified);
          console.log('typeof', typeof res.data.verified);
          console.log('status', res.status);
          console.log('statusText', res.statusText);

          if (res.status === 200 && res.data.verified === false) {
            this.errors.push('Invalid email, please provide real one..');
            setTimeout(() => {
              this.btn_disabled = false;
              this.errors = [];
            }, 7500);
            this.loading = false;
            return false;
          }
        })
        .catch(error => {
          console.log('Email validate error', error);
        });

      if (this.errors.length > 0) {
        this.loading = false;
        return false;
      }

      const form_data = new FormData(this.$refs.form);

      const stop_loader = () => {
        this.recaptchaExpired();
        setTimeout(() => {
          this.btn_disabled = false;
          this.errors = [];
          this.response = null;
        }, 7500);
        this.loading = false;
      };

      await axios
        .post(cfg_axios.base_url + '/account/create', form_data, {
          headers: cfg_axios.headers,
        })
        .then(response => {
          this.response = response.data;

          const data = {
            welcome:
              'Welcome to LA2Dream, you have been successfully signed up..\n' +
              'Store this data in safe place & have fun gameplay!',
            user: this.prefixSwitcher ? this.form.prefix + this.form.username : this.form.username,
            password: this.form.password,
            email: this.form.email,
          };
          let text =
            data.welcome +
            '\n\nUsername: ' +
            data.user +
            '\nPassword: ' +
            data.password +
            '\nE-mail: ' +
            data.email;

          setTimeout(() => {
            this.downloadItem('account_credentials.txt', text);
          }, 1500);

          stop_loader();
        })
        .catch(error => {
          this.errors.push(
            error.response.data.messages[Object.keys(error.response.data.messages)[0]]
          );
          stop_loader();
        });
    },
    randInt() {
      return Math.floor(Math.random() * 8999) + 1000;
    },
    random_prefix(length = 4) {
      let result = '';
      const characters = '0123456789';
      let i = 0;
      for (i; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    downloadItem(filename, data) {
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    valid_prefix() {
      return /^([a-zA-Z0-9]{4})$/.test(this.form.prefix);
    },
    valid_username() {
      return /^([a-zA-Z0-9]{4,})$/.test(this.form.username);
    },
  },
  mounted() {
    this.errors = [];
    this.response = null;
    this.$store.dispatch('init_signup');
    this.form.prefix = this.random_prefix();
  },
  updated() {
    if (!this.initiated) {
      this.initiated = true;
    }
  },
};
</script>

<style scoped></style>
